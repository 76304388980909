import React from "react";
import '../css/styles.css'
import IconText from "./icon_text";
import CpuIcon from "@iconify/icons-bi/cpu";
import GpuIcon from "@iconify/icons-whh/graphicscard";
import RamIcon from "@iconify/icons-ps/ram";
import SsdIcon from "@iconify/icons-icon-park-outline/ssd";
import DisplayIcon from "@iconify/icons-icons8/monitor"

export default function MyGear() {
    return (<div className="mt-4">
            <IconText icon={CpuIcon} text="Ryzen 3990X"/>
            <br/>
            <IconText icon={GpuIcon} text="RTX 3090"/>
            <br/>
            <IconText icon={RamIcon} text="128gb 3200mhz DDR4"/>
            <br/>
            <IconText icon={SsdIcon} text="1tb NVME SSD"/>
            <br/>
            <IconText icon={SsdIcon} text="512gb SATA SSD"/>
            <br/>
            <IconText icon={SsdIcon} text="128gb SATA SSD"/>
            <br/>
            <IconText icon={DisplayIcon} text="3440x1440 144hz Ultrawide"/>
            <br/>
            <IconText icon={DisplayIcon} text="3440x1440 144hz Ultrawide"/>
            <br/>
            <IconText icon={DisplayIcon} text="3440x1440 144hz Ultrawide"/>
        </div>
    );
}
