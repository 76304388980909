import React from "react";
import '../css/styles.css'

import {Icon, InlineIcon} from '@iconify/react';

export default function IconText(props) {
    let text = <div className="flex m-auto text-gray mx-4">
        {props.text}
    </div>;

    if (props.text.split("#").length > 1) {
        text =
            <ul class="list-disc mx-9 mt-3">
                {props.text.split("#").map(split => {
                    return <li className="text-gray">
                        {split}
                    </li>
                })}
            </ul>
    }

    return (<div className="inline-flex">
        <div className="flex">
            <InlineIcon icon={props.icon} color="#CCCCCC" width="48px"/>
        </div>
        {text}
    </div>);
}