import React, {useEffect} from "react";
import './css/styles.css';
import SidePanel from "./components/side_panel";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import AboutMe from "./components/about_me";
import Contact from "./components/contact";
import MyGear from "./components/my_gear";
import VerticalLine from "./components/vertical_line";
import Blog from "./components/blog";



export default function Portfolio() {
    return (<div className="md:flex">
            <div className="flex md:mx-8 mt-4">
                <div>
                    <SidePanel/>
                    <div className="pl-8 md:pl-0">
                        <Switch>
                            <Route path="/about">
                                <AboutMe/>
                            </Route>
                            <Route path="/contact">
                                <Contact/>
                            </Route>
                            <Route path="/gear">
                                <MyGear/>
                            </Route>
                            <Route path="/">
                                <AboutMe/>
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
            {/*<div className="h-screen flex mt-6">*/}
                {/*<VerticalLine height="h-screen" className=""/>*/}
            {/*</div>*/}
            <div className="mx-8 mt-4 align-top">
                <Blog/>
            </div>
        </div>
    );
}
