import React from 'react';
import * as ReactDOM from "react-dom";
import Portfolio from "./portfolio";
import { BrowserRouter, Route, Switch } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Portfolio />
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
