import React, {useEffect, useState} from "react";
import '../css/styles.css'
import BlogEntry from "./blog_entry";
import fetch from 'node-fetch';
import toml from 'toml';

export default function Blog() {

    let [posts, setPosts] = useState([]);

    useEffect(async () => {
        let newPosts = [];

        const count = await (await fetch('https://raw.githubusercontent.com/LegendWasTaken/blog-posts/main/post-count')).text();

        let contentPromises = new Array(count);
        let promises = (new Array(count)).map((_, i) => {
            contentPromises[i] = fetch(`https://raw.githubusercontent.com/LegendWasTaken/blog-posts/main/blog${i}/post.md`);
            return fetch(`https://raw.githubusercontent.com/LegendWasTaken/blog-posts/main/blog${i}/manifest.toml`);
        });
        contentPromises = await Promise.all(contentPromises);
        contentPromises = contentPromises.map(promise => {
            return promise.text();
        })
        promises = await Promise.all(promises);
        promises = promises.map(promise => {
            return promise.text();
        })

        contentPromises = await Promise.all(contentPromises);
        contentPromises.forEach(promise => {
            newPosts.push(promise);
        });
        promises = await Promise.all(promises);
        let manifests = promises.map(promise => {
            return toml.parse(promise);
        });

        for (let i = 0; i < count; i++) {
            newPosts[i] = {
                name: manifests[i].title,
                description: manifests[i].description,
                date: manifests[i].creation_date,
                content: newPosts[i]
            };
        }

        console.log(newPosts);

        setPosts(newPosts);
    }, []);

    return (<div>
            <p className="text-gray text-2xl pb-4">Blog Posts</p>

            {posts.map(post => {
                return <BlogEntry name={post.name} description={post.description} date={post.date} content={post.content}/>
            })}

        </div>
    );
}
