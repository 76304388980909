import React from "react";
import {Link} from "react-router-dom";
import '../css/styles.css'
import VerticalLine from "./vertical_line";
import HorizontalLine from "./horizontal_line";
import { useLocation } from 'react-router-dom'

export default function Navigation() {
    let path = useLocation().pathname;

    let aboutWeight = path === "/about" ? "font-medium underline" : "";
    let contactWeight = path === "/contact" ? "font-medium underline" : "";
    let gearWeight = path === "/gear" ? "font-medium underline" : "";

    return (<div className="">
            <div className="flex justify-items-center">
                <div className="inline-block m-auto flex">
                    <Link className={"whitespace-nowrap text-gray text-2xl " + aboutWeight} to="/about">About Me</Link>
                </div>
                <div className="inline-block mx-4">
                    <VerticalLine height="h-8"/>
                </div>
                <div className="inline-block m-auto flex">
                    <Link className={"whitespace-nowrap text-gray text-2xl " + contactWeight} to="/contact">Contact</Link>
                </div>
                <div className="inline-block mx-4">
                    <VerticalLine height="h-8"/>
                </div>
                <div className="inline-block m-auto flex">
                    <Link className={"whitespace-nowrap text-gray text-2xl " + gearWeight} to="/gear">My Gear</Link>
                </div>
            </div>
            <div className="pt-4 w-screen md:w-11/12 md:mx-auto">
                <div className="w-1/2 mx-auto w-11/12 md:w-full">
                    <HorizontalLine className="pt-6"/>
                </div>
            </div>
        </div>
    );
}
