import React from "react";
import { render } from 'react-dom';
import ReactMarkdown from "react-markdown";
import gfm from 'remark-gfm';
import '../css/styles.css'
import HorizontalLine from "./horizontal_line";

export default function BlogEntry(props) {
    return (<div>
                <HorizontalLine className="pt-6"/>
            <p className="text-gray text-3xl pl-4 pt-3 font-medium">{props.name}</p>
            <p className="text-gray text-1xl pl-4">{props.description}</p>
            <p className="text-gray text-xs pl-4 pt-4 italic">{props.date}</p>
            <div className="pl-4 pt-4 text-gray whitespace-normal overflow-hidden w-auto w-4/5">
                <ReactMarkdown remarkPlugins={[gfm]} children={props.content} />
            </div>
            {/**/}

            <div className="pb-8"/>
        </div>
    );
}
