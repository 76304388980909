import React from "react";
import '../css/styles.css'
import IconText from "./icon_text";
import EmailIcon from "@iconify/icons-carbon/email";
import GithubIcon from "@iconify/icons-akar-icons/github-outline-fill"

export default function Contact() {
    return (<div className="mt-4">
            <IconText icon={EmailIcon} text="contact@legend.xyz"/>
            <br/>
            <IconText icon={GithubIcon} text="LegendWasTaken"/>
        </div>
    );
}
