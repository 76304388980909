import React from "react";
import '../css/styles.css'
import Navigation from "./navigation";

export default function SidePanel() {
    return (<div className="inline-block">
            <Navigation/>
        </div>
        );
}
