import React from "react";
import '../css/styles.css'
import IconText from "./icon_text";
import FaceIcon from "@iconify/icons-wpf/name"
import WorldIcon from "@iconify/icons-bx/bx-world"
import QuestionIcon from "@iconify/icons-ant-design/question-circle-outlined"
import CodeIcon from "@iconify/icons-ant-design/code-outlined"

export default function AboutMe() {
    return (<div className="mt-4">
            <IconText icon={FaceIcon} text="Caio (Legend), 18"/>
            <br/>
            <IconText icon={WorldIcon} text="Singapore, Singapore"/>
            <br/>
            <IconText icon={QuestionIcon}
                      text="Photography#Music Production#Trampolines#Skateboarding#Carpentry#Rubiks Cubes"/>
            <br/>
            <IconText icon={CodeIcon} text="C++#Java#Javascript#OpenGL#SQL#REST#React#Tailwind#TCP#WinAPI"/>
        </div>
    );
}
